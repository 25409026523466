<template>
  <dashboard>
    <v-row>
      <v-col cols="12">
        <h1>Poolmanagement</h1>
      </v-col>
      <v-col cols="12">
        <v-menu>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              outlined
              dark
              v-bind="attrs"
              v-on="on"
              v-if="selected_pool.id != 0"
            >
              <v-icon>mdi-account</v-icon>{{ selected_pool.id }} -
              {{ selected_pool.mail }}
            </v-btn>
            <v-btn outlined dark v-bind="attrs" v-on="on" v-else>
              <v-icon>mdi-account</v-icon>Select Pool
            </v-btn>
          </template>

          <v-list style="max-height: 300px" class="overflow-y-auto">
            <v-list-item
              v-for="(item, i) in pools"
              :key="i"
              @click="setPool(item)"
            >
              <v-list-item-title
                >{{ item.pool_id }} -
                {{ item.pool_apollo_email }}</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
      <v-col cols="12">
        <v-card outlined>
          <v-card-text>
            <v-row class="text-subtitle-2">
              <v-col cols="2"> </v-col>
              <v-col cols="1">Exchange</v-col>
              <v-col cols="9">
                <v-row>
                  <v-col cols="2">Quote</v-col>
                  <v-col cols="2">Value</v-col>
                  <v-col cols="2">Share %</v-col>
                  <v-col cols="5" class="text-center">Change</v-col>
                  <v-col cols="1"></v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card
          v-for="(item, key) in pool_user_shares"
          :key="key"
          outlined
          class="ma-1"
        >
          <v-card-text>
            <v-row v-for="(exc, lol) in item" :key="lol">
              <v-col cols="12" v-if="lol != 1"><v-divider></v-divider></v-col>
              <v-col cols="2" class="pt-5"
                ><span v-if="lol == 1">
                  {{ exc[0].first_name + " " + exc[0].last_name }}</span
                ></v-col
              >
              <v-col cols="1" class="pt-5">{{ exc[0].exchange }}</v-col>

              <v-col cols="9">
                <v-row
                  v-for="(quo, klo) in exc"
                  :key="klo"
                  class="justify-center align-center"
                >
                  <v-col cols="12" v-if="klo != 0"
                    ><v-divider></v-divider
                  ></v-col>
                  <v-col cols="2">
                    {{ quo.quote_name }}
                  </v-col>
                  <v-col cols="2">
                    {{ quo.quote_value }}
                  </v-col>
                  <v-col cols="2">
                    {{ quo.share_percentage }}
                  </v-col>
                  <v-col cols="5">
                    <v-text-field
                      dense
                      hide-details
                      v-model="quo.change_value"
                      solo-inverted
                    ></v-text-field>
                  </v-col>
                  <v-col cols="1">
                    <v-btn @click="checkChange(quo)" dense color="primary">
                      SAVE
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" persistent max-width="900">
      <v-card>
        <v-card-title class="text-h5">
          Calculated Changes
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              cols="6"
              v-for="(user, index) in calculated_user_values"
              :key="index"
            >
              <v-list align="left">
                <v-list-item class="lighten-4">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ user.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      USER
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="divider" />
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ user.exchange }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      exchange
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="divider" />
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ user.quote_name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      Changed Quote
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="divider" />
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      v-if="user.partial_quote_value == user.new_quote_value"
                    >
                      <span>{{ user.partial_quote_value }} </span>
                      <v-icon small class="pb-1 mx-3">mdi-arrow-right</v-icon>
                      <span> {{ user.new_quote_value }}</span>
                    </v-list-item-title>
                    <v-list-item-title v-else>
                      <span>{{ user.partial_quote_value }} </span>
                      <v-icon small class="pb-1 mx-3">mdi-arrow-right</v-icon>
                      <span
                        v-bind:class="[
                          user.partial_quote_value < user.new_quote_value
                            ? 'green--text'
                            : 'red--text',
                        ]"
                      >
                        {{ user.new_quote_value }}
                      </span>
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      quote value change
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="divider" />
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      <span>{{ user.share_percentage }} % </span
                      ><v-icon small class="pb-1 mx-3">mdi-arrow-right</v-icon>
                      <span
                        v-bind:class="[
                          user.share_percentage < user.new_share_percentage
                            ? 'green--text'
                            : 'red--text',
                        ]"
                        >{{ user.new_share_percentage }} %</span
                      >
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      quote percentage change
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialog = false">
            Disagree
          </v-btn>
          <v-btn color="green darken-1" text @click="saveChanges()">
            Agree
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </dashboard>
</template>

<script>
import dashboard from "./Dashboard.vue";
import axios from "axios";
export default {
  name: "Poolmanagement",
  components: {
    dashboard,
  },
  data() {
    return {
      pools: [],
      dialog: false,
      current_change: [],
      pool_user_shares: {},
      selected_pool: {
        mail: "",
        id: 0,
        user_id: 0,
      },
      calculated_user_values: [],
    };
  },
  mounted() {
    this.getPools();
  },
  methods: {
    setPool(item) {
      this.selected_pool.mail = item.pool_apollo_email;
      this.selected_pool.id = item.pool_id;
      this.selected_pool.user_id = item.gsmg_user_id;
      this.getPoolShares();
    },
    async getPools() {
      let token = await this.$auth.getTokenSilently();
      await axios
        .get(process.env.VUE_APP_API_URL + "/api/pool_management/pools", {
          headers: {
            Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
          },
        })
        .then((response) => {
          this.pools = response.data.pools;
        });
    },
    async getPoolShares() {
      this.pool_user_shares = {};
      let token = await this.$auth.getTokenSilently();
      await axios
        .get(
          process.env.VUE_APP_API_URL +
            "/api/pool_management/pool_user_shares?pool_id=" +
            this.selected_pool.id +
            "&user_id=" +
            this.selected_pool.user_id,
          {
            headers: {
              Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
            },
          }
        )
        .then((response) => {
          this.pool_user_shares = response.data.pool_user_shares;
        });
    },
    async checkChange(quote) {
      this.current_change = quote;
      console.log(quote);
      // GET USER PERCENTAGES FROM POOL QUOTE
      let token = await this.$auth.getTokenSilently();
      let datar = {
        exchange_id: quote.exchange_id,
        quote_id: quote.quote_id,
        pool_id: quote.pool_id,
        user_id: this.selected_pool.user_id,
      };
      let { data } = await axios.post(
        process.env.VUE_APP_API_URL +
          "/api/pool_management/pool_percentages_quote",
        datar,
        {
          headers: {
            Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
          },
        }
      );
      let pool_percentages_quote = data.pool_percentages_quote;
      let database_quote_value = data.current_quote_values[0].value;
      let database_quote_value_new = data.current_quote_values[0].value;
      // CALC NEW PERCENTAGES
      this.calculated_user_values = [];
      for (let i in pool_percentages_quote) {
        let uv = {};
        // Basic info
        console.log(pool_percentages_quote[i]);
        uv.user_id = pool_percentages_quote[i].user_id;
        uv.name =
          pool_percentages_quote[i].first_name +
          " " +
          pool_percentages_quote[i].last_name;
        uv.quote_name = this.current_change.quote_name;
        uv.exchange = this.current_change.exchange;

        uv.partial_quote_value =
          (pool_percentages_quote[i].share_percentage / 100) *
          database_quote_value;
        uv.share_percentage = pool_percentages_quote[i].share_percentage;
        if (pool_percentages_quote[i].user_id == this.current_change.user_id) {
          // NEW OVERALL QUOTE VALUE
          database_quote_value_new += parseInt(
            this.current_change.change_value,
            10
          );

          uv.new_quote_value =
            uv.partial_quote_value +
            parseInt(this.current_change.change_value, 10);
        } else {
          uv.new_quote_value = uv.partial_quote_value;
        }

        this.calculated_user_values.push(uv);
      }
      console.log(database_quote_value_new);
      for (let cuv in this.calculated_user_values) {
        // // new percent values
        this.calculated_user_values[cuv].new_share_percentage =
          (this.calculated_user_values[cuv].new_quote_value /
            database_quote_value_new) *
          100;
      }

      console.log(this.calculated_user_values);

      this.dialog = true;
    },
    async saveChanges() {
      this.dialog = false;
    },
  },
};
</script>

<style></style>
