<template>
  <div class="main" :style="mainStyle">
    <v-app-bar color="transparent" flat dense>
      <v-btn
        class=" mt-7 login-button"
        large
        color="#d2b07f"
        to="/dashboard"
        light
      >
        <strong>Login</strong>
      </v-btn>
    </v-app-bar>
    <v-footer app absolute>
      <v-row justify="center" no-gutters>
        <v-btn text x-small to="/impressum" color="white">
          Impressum
        </v-btn>
      </v-row>
    </v-footer>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      mainStyle: {
        backgroundImage: `url(${require("@/assets/logo.svg")})`,
      },
      lel: true,
    };
  },
  computed: {},
  mounted() {
    this.$vuetify.theme.dark = localStorage.getItem("dark_mode");
  },
  methods: {},
};
</script>

<style>
.main {
  height: 100% !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}
.login-button {
  color: "#d2b07f";
}
</style>
