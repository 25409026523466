<template>
  <v-container>
    <v-row no-gutters justify="center">
      <v-col cols="10" sm="10">
        <v-card class="pa-2" outlined tile>
          <v-img :src="imgs.head" height="350px"></v-img>

          <v-card-title>
            <h1>Impressum</h1>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                Informationspflicht laut §5 E-Commerce Gesetz, §14
                Unternehmensgesetzbuch, §63 Gewerbeordnung und
                Offenlegungspflicht laut §25 Mediengesetz.
              </v-col>
              <v-col cols="12">
                Apollo Institut<br />
                ZVR:1476507079
              </v-col>
              <v-col cols="12">
                <h3>Verreinssitz:</h3>
                Margeritenstraße 6<br />
                3032 Eichgraben<br />
                Österreich
              </v-col>
              <v-col cols="12">
                <h3>Organschaftliche Vertreter</h3>
                Präsident: David Schüssler<br />
                Vizepräsident: Bernd Schüssler
              </v-col>
              <v-col cols="12">
                <strong>E-Mail:</strong
                ><a href="mailto:office@apolloinstitute.at"
                  >office@apolloinstitute.at</a
                ><br />

                <strong>Vereinszweck:</strong> Forschungs-und Bildungsverein
                rund um die Themen digitale Tendenzen und Innovationen mit der
                Selbsthilfe für eine bessere Lebensqualität
              </v-col>
              <v-col cols="12">
                <h2>EU-Streitschlichtung</h2>
                <br />
                <p>
                  Gemäß Verordnung über Online-Streitbeilegung in
                  Verbraucherangelegenheiten (ODR-Verordnung) möchten wir Sie
                  über die Online-Streitbeilegungsplattform (OS-Plattform)
                  informieren.Verbraucher haben die Möglichkeit, Beschwerden an
                  die Online Streitbeilegungsplattform der Europäischen
                  Kommission unter
                  <a href="http://ec.europa.eu/odr?tid=121751972"
                    >http://ec.europa.eu/odr?tid=121751972</a
                  >
                  zu richten. Die dafür notwendigen Kontaktdaten finden Sie
                  oberhalb in unserem Impressum.
                </p>
                <p>
                  Wir möchten Sie jedoch darauf hinweisen, dass wir nicht bereit
                  oder verpflichtet sind, an Streitbeilegungsverfahren vor einer
                  Verbraucherschlichtungsstelle teilzunehmen.
                </p>
              </v-col>
              <v-col cols="12">
                <h2>Haftung für Inhalte dieser Website</h2>
                <br />
                <p>
                  Wir entwickeln die Inhalte dieser Webseite ständig weiter und
                  bemühen uns korrekte und aktuelle Informationen
                  bereitzustellen. Leider können wir keine Haftung für die
                  Korrektheit aller Inhalte auf dieser Website übernehmen,
                  speziell für jene, die seitens Dritter bereitgestellt wurden.
                  Als Diensteanbieter sind wir nicht verpflichtet, die von ihnen
                  übermittelten oder gespeicherten Informationen zu überwachen
                  oder nach Umständen zu forschen, die auf eine rechtswidrige
                  Tätigkeit hinweisen.
                </p>
                <p>
                  Unsere Verpflichtungen zur Entfernung von Informationen oder
                  zur Sperrung der Nutzung von Informationen nach den
                  allgemeinen Gesetzen aufgrund von gerichtlichen oder
                  behördlichen Anordnungen bleiben auch im Falle unserer
                  Nichtverantwortlichkeit davon unberührt.
                </p>
                <p>
                  Sollten Ihnen problematische oder rechtswidrige Inhalte
                  auffallen, bitte wir Sie uns umgehend zu kontaktieren, damit
                  wir die rechtswidrigen Inhalte entfernen können. Sie finden
                  die Kontaktdaten im Impressum.
                </p>
              </v-col>
              <v-col cols="12">
                <h2>Haftung für Links auf dieser Webseite</h2>
                <br />
                <p>
                  Unsere Webseite enthält Links zu anderen Webseiten für deren
                  Inhalt wir nicht verantwortlich sind. Haftung für verlinkte
                  Websites besteht für uns nicht, da wir keine Kenntnis
                  rechtswidriger Tätigkeiten hatten und haben, uns solche
                  Rechtswidrigkeiten auch bisher nicht aufgefallen sind und wir
                  Links sofort entfernen würden, wenn uns Rechtswidrigkeiten
                  bekannt werden.
                </p>

                <p>
                  Wenn Ihnen rechtswidrige Links auf unserer Website auffallen,
                  bitte wir Sie uns zu kontaktieren. Sie finden die Kontaktdaten
                  im Impressum.
                </p>
              </v-col>
              <v-col cols="12">
                <h2>Urheberrechtshinweis</h2>
                <br />
                <p>
                  AlleInhalte dieser Webseite (Bilder, Fotos, Texte, Videos)
                  unterliegen dem Urheberrecht. Bitte fragen Sie uns bevor Sie
                  die Inhalte dieser Website verbreiten, vervielfältigen oder
                  verwerten wie zum Beispiel auf anderen Websites erneut
                  veröffentlichen. Falls notwendig, werden wir die unerlaubte
                  Nutzung von Teilen der Inhalte unserer Seite rechtlich
                  verfolgen.
                </p>
                <p>
                  Sollten Sie auf dieser Webseite Inhalte finden, die das
                  Urheberrecht verletzen, bitten wir Sie uns zu kontaktieren.
                </p>
              </v-col>
              <v-col cols="12">
                <h2>Bildernachweis</h2>
                <br />
                <p>
                  Die Bilder, Fotos und Grafiken auf dieser Webseite sind
                  urheberrechtlich geschützt.
                </p>
                <p>
                  Die Bilderrechte liegen bei den folgenden Fotografen und
                  Unternehmen:
                </p>
                <ul>
                  <li>David Schüssler</li>
                  <li>Bernd Schüssler</li>
                </ul>
              </v-col>
              <v-col cols="12">
                <h1>Datenschutzerklärung</h1>
                <br />
                <h2>Einleitung und Überblick</h2>
                <br />
                <p>
                  Wir haben diese Datenschutzerklärung (Fassung
                  30.05.2021-121751972) verfasst, um Ihnen gemäß der Vorgaben
                  der
                  <a
                    href="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679&tid=121751972"
                    >Datenschutz-Grundverordnung (EU) 2016/679
                  </a>
                  und anwendbaren nationalen Gesetzen zu erklären, welche
                  personenbezogenen Daten (kurz Daten) wir als Verantwortliche –
                  und die von uns beauftragten Auftragsverarbeiter (z. B.
                  Provider) – verarbeiten, zukünftig verarbeiten werden und
                  welche rechtmäßigen Möglichkeiten Sie haben. Die verwendeten
                  Begriffe sind geschlechtsneutral zu verstehen. Kurz gesagt:
                  Wir informieren Sie umfassend über Daten, die wir über Sie
                  verarbeiten.
                </p>
                <p>
                  Datenschutzerklärungen klingen für gewöhnlich sehr technisch
                  und verwenden juristische Fachbegriffe. Diese
                  Datenschutzerklärung soll Ihnen hingegen die wichtigsten Dinge
                  so einfach und transparent wie möglich beschreiben. Soweit es
                  der Transparenz förderlich ist, werden technische Begriffe
                  leserfreundlich erklärt, Links zu weiterführenden
                  Informationen geboten und Grafiken zum Einsatz gebracht. Wir
                  informieren damit in klarer und einfacher Sprache, dass wir im
                  Rahmen unserer Geschäftstätigkeiten nur dann personenbezogene
                  Daten verarbeiten, wenn eine entsprechende gesetzliche
                  Grundlage gegeben ist. Das ist sicher nicht möglich, wenn man
                  möglichst knappe, unklare und juristisch-technische
                  Erklärungen abgibt, so wie sie im Internet oft Standard sind,
                  wenn es um Datenschutz geht. Ich hoffe Sie finden die
                  folgenden Erläuterungen interessant und informativ und
                  vielleicht ist die eine oder andere Information dabei, die Sie
                  noch nicht kannten. Wenn trotzdem Fragen bleiben, möchten wir
                  Sie bitten, sich an die unten bzw. im Impressum genannte
                  verantwortliche Stelle zu wenden, den vorhandenen Links zu
                  folgen und sich weitere Informationen auf Drittseiten
                  anzusehen. Unsere Kontaktdaten finden Sie selbstverständlich
                  auch im Impressum.
                </p>
              </v-col>
              <v-col cols="12">
                <h2>Anwendungsbereich</h2>
                <br />
                <p>
                  Diese Datenschutzerklärung gilt für alle von uns im
                  Unternehmen verarbeiteten personenbezogenen Daten und für alle
                  personenbezogenen Daten, die von uns beauftragte Firmen
                  (Auftragsverarbeiter) verarbeiten. Mit personenbezogenen Daten
                  meinen wir Informationen wie zum Beispiel Name, E-Mail-Adresse
                  und postalische Anschrift einer Person. Die Verarbeitung
                  personenbezogener Daten sorgt dafür, dass wir unsere
                  Dienstleistungen und Produkte anbieten und abrechnen können,
                  sei es online oder offline. Der Anwendungsbereich dieser
                  Datenschutzerklärung umfasst:
                </p>
                <ul>
                  <li>
                    alle Onlineauftritte (Websites, Onlineshops), die wir
                    betreiben
                  </li>
                  <li>Social Media Auftritte und E-Mail-Kommunikation</li>
                  <li>mobile Apps für Smartphones und andere Geräte</li>
                </ul>
                <br />
                <p>
                  Kurz gesagt: Die Datenschutzerklärung gilt für alle Bereiche,
                  in denen personenbezogene Daten im Unternehmen strukturiert
                  verarbeitet werden.
                </p>
              </v-col>
              <v-col cols="12">
                <h2>Rechtsgrundlagen</h2>
                <br />
                <p>
                  In der folgenden Datenschutzerklärung geben wir Ihnen
                  transparente Informationen zu den rechtlichen Grundsätzen und
                  Vorschriften, also den Rechtsgrundlagen der
                  Datenschutz-Grundverordnung, die uns ermöglichen,
                  personenbezogene Daten zu verarbeiten. Was das EU-Recht
                  betrifft, beziehen wir uns auf die VERORDNUNG (EU) 2016/679
                  DES EUROPÄISCHEN PARLAMENTS UND DES RATES vom 27. April 2016.
                  Diese Datenschutz-Grundverordnung der EU können Sie
                  selbstverständlich online auf EURLex, dem Zugang zum EU-Recht,
                  unter
                  <a
                    href="https://eur-lex.europa.eu/legalcontent/DE/TXT/?uri=celex%3A32016R0679"
                    >https://eur-lex.europa.eu/legalcontent/DE/TXT/?uri=celex%3A32016R0679</a
                  >
                  nachlesen. Wir verarbeiten Ihre Daten nur, wenn mindestens
                  eine der folgenden Bedingungen zutrifft:
                </p>
                <ul>
                  <li>
                    Einwilligung (Artikel 6 Absatz 1 lit. a DSGVO): Sie haben
                    uns Ihre Einwilligung gegeben, Daten zu einem bestimmten
                    Zweck zu verarbeiten. Ein Beispiel wäre die Speicherung
                    Ihrer eingegebenen Daten eines Kontaktformulars.
                  </li>
                  <li>
                    Vertrag (Artikel 6 Absatz 1 lit. b DSGVO): Um einen Vertrag
                    oder vorvertragliche Verpflichtungen mit Ihnen zu erfüllen,
                    verarbeiten wir Ihre Daten. Wenn wir zum Beispiel einen
                    Kaufvertrag mit Ihnen abschließen, benötigen wir vorab
                    personenbezogene Informationen.
                  </li>
                  <li>
                    Rechtliche Verpflichtung (Artikel 6 Absatz 1 lit. c DSGVO):
                    Wenn wir einer rechtlichen Verpflichtung unterliegen,
                    verarbeiten wir Ihre Daten. Zum Beispiel sind wir gesetzlich
                    verpflichtet Rechnungen für die Buchhaltung aufzuheben.
                    Diese enthalten in der Regel personenbezogene Daten.
                  </li>
                  <li>
                    Berechtigte Interessen (Artikel 6 Absatz 1 lit. f DSGVO): Im
                    Falle berechtigter Interessen, die Ihre Grundrechte nicht
                    einschränken, behalten wir uns die Verarbeitung
                    personenbezogener Daten vor. Wir müssen zum Beispiel gewisse
                    Daten verarbeiten, um unsere Website sicher und
                    wirtschaftlich effizient betreiben zu können. Diese
                    Verarbeitung ist somit ein berechtigtes Interesse.
                  </li>
                </ul>
                <br />
                <p>
                  Weitere Bedingungen wie die Wahrnehmung von Aufnahmen im
                  öffentlichen Interesse und Ausübung öffentlicher Gewalt sowie
                  dem Schutz lebenswichtiger Interessen treten bei uns in der
                  Regel nicht auf. Soweit eine solche Rechtsgrundlage doch
                  einschlägig sein sollte, wird diese an der entsprechenden
                  Stelle ausgewiesen.
                </p>
                <p>
                  Zusätzlich zu der EU-Verordnung gelten auch noch nationale
                  Gesetze:
                </p>
                <ul>
                  <li>
                    In Österreich ist dies das Bundesgesetz zum Schutz
                    natürlicher Personen bei der Verarbeitung personenbezogener
                    Daten (Datenschutzgesetz), kurz DSG.
                  </li>
                  <li>
                    In Deutschland gilt das Bundesdatenschutzgesetz, kurz BDSG.
                  </li>
                </ul>
                <br />
                <p>
                  Sofern weitere regionale oder nationale Gesetze zur Anwendung
                  kommen, informieren wir Sie in den folgenden Abschnitten
                  darüber.
                </p>
              </v-col>
              <v-col cols="12">
                <h2>Kontaktdaten des Verantwortlichen</h2>
                <br />
                <p>
                  Sollten Sie Fragen zum Datenschutz haben, finden Sie
                  nachfolgend die Kontaktdaten der verantwortlichen Person bzw.
                  Stelle.<br />
                  Apollo Institut<br />
                  Margeritenstraße 6, 3032 Eichgraben<br />
                  Vertretungsberechtigt: David Schüssler<br />
                  E-Mail:
                  <a href="mailto:office@apolloinstitute.at"
                    >office@apolloinstitute.at</a
                  ><br />
                  Impressum:
                  <a href="https://www.apolloinstitute.at/#/impressum"
                    >https://www.apolloinstitute.at/#/impressum</a
                  >
                </p>
              </v-col>
              <v-col cols="12">
                <h2>Speicherdauer</h2>
                <br />
                <p>
                  Dass wir personenbezogene Daten nur so lange speichern, wie es
                  für die Bereitstellung unserer Dienstleistungen und Produkte
                  unbedingt notwendig ist, gilt als generelles Kriterium bei
                  uns. Wenn es zum Beispiel im Fall von Buchhaltung gesetzlich
                  vorgeschrieben ist, kann diese Speicherdauer auch
                  überschritten werden. Das bedeutet, dass wir personenbezogene
                  Daten löschen, sobald der Grund für die Datenverarbeitung
                  nicht mehr vorhanden ist. Sollten Sie die Löschung Ihrer Daten
                  wünschen oder die Einwilligung zur Datenverarbeitung
                  widerrufen, werden die Daten so rasch wie möglich und soweit
                  keine Pflicht zur Speicherung besteht, gelöscht.<br />
                  Über die konkrete Dauer der jeweiligen Datenverarbeitung
                  informieren wir Sie weiter unten, sofern wir weitere
                  Informationen dazu haben.
                </p>
              </v-col>
              <v-col cols="12">
                <h2>Rechte laut Datenschutzgrundverordnung</h2>
                <br />
                <p>
                  Laut Artikel 13 DSGVO stehen Ihnen die folgenden Rechte zu,
                  damit es zu einer fairen und transparenten Verarbeitung von
                  Daten kommt:
                </p>
                <ul>
                  <li>
                    Sie haben laut Artikel 15 DSGVO ein Auskunftsrecht darüber,
                    ob wir Daten von Ihnen verarbeiten. Sollte das zutreffen,
                    haben Sie Recht darauf eine Kopie der Daten zu erhalten und
                    die folgenden Informationen zu erfahren:
                  </li>
                  <ul>
                    <li>zu welchem Zweck wir die Verarbeitung durchführen;</li>
                    <li>
                      die Kategorien, also die Arten von Daten, die verarbeitet
                      werden;
                    </li>
                    <li>
                      wer diese Daten erhält und wenn die Daten an Drittländer
                      übermittelt werden, wie die Sicherheit garantiert werden
                      kann;
                    </li>
                    <li>wie lange die Daten gespeichert werden;</li>
                    <li>
                      das Bestehen des Rechts auf Berichtigung, Löschung oder
                      Einschränkung der Verarbeitung und dem Widerspruchsrecht
                      gegen die Verarbeitung;
                    </li>
                    <li>
                      dass Sie sich bei einer Aufsichtsbehörde beschweren können
                      (Links zu diesen Behörden finden Sie weiter unten);
                    </li>
                    <li>
                      die Herkunft der Daten, wenn wir sie nicht bei Ihnen
                      erhoben haben;
                    </li>
                    <li>
                      ob Profiling durchgeführt wird, ob also Daten automatisch
                      ausgewertet werden, um zu einem persönlichen Profil von
                      Ihnen zu gelangen.
                    </li>
                  </ul>
                  <li>
                    Sie haben laut Artikel 16 DSGVO ein Recht auf Berichtigung
                    der Daten, was bedeutet, dass wir Daten richtig stellen
                    müssen, falls Sie Fehler finden.
                  </li>
                  <li>
                    Sie haben laut Artikel 17 DSGVO das Recht auf Löschung
                    („Recht auf Vergessenwerden“), was konkret bedeutet, dass
                    Sie die Löschung Ihrer Daten verlangen dürfen.
                  </li>
                  <li>
                    Sie haben laut Artikel 18 DSGVO das Recht auf Einschränkung
                    der Verarbeitung, was bedeutet, dass wir die Daten nur mehr
                    speichern dürfen aber nicht weiter verwenden.
                  </li>
                  <li>
                    Sie haben laut Artikel 19 DSGVO das Recht auf
                    Datenübertragbarkeit, was bedeutet, dass wir Ihnen auf
                    Anfrage Ihre Daten in einem gängigen Format zur Verfügung
                    stellen.
                  </li>
                  <li>
                    Sie haben laut Artikel 21 DSGVO ein Widerspruchsrecht,
                    welches nach Durchsetzung eine Änderung der Verarbeitung mit
                    sich bringt.
                  </li>
                  <ul>
                    <li>
                      Wenn die Verarbeitung Ihrer Daten auf Artikel 6 Abs. 1
                      lit. e (öffentliches Interesse, Ausübung öffentlicher
                      Gewalt) oder Artikel 6 Abs. 1 lit. f (berechtigtes
                      Interesse) basiert, können Sie gegen die Verarbeitung
                      Widerspruch einlegen. Wir prüfen danach so rasch wie
                      möglich, ob wir diesem Widerspruch rechtlich nachkommen
                      können
                    </li>
                    <li>
                      Werden Daten verwendet, um Direktwerbung zu betreiben,
                      können Sie jederzeit gegen diese Art der Datenverarbeitung
                      widersprechen. Wir dürfen Ihre Daten danach nicht mehr für
                      Direktmarketing verwenden.
                    </li>
                    <li>
                      Werden Daten verwendet, um Profiling zu betreiben, können
                      Sie jederzeit gegen diese Art der Datenverarbeitung
                      widersprechen. Wir dürfen Ihre Daten danach nicht mehr für
                      Profiling verwenden.
                    </li>
                  </ul>
                  <li>
                    Sie haben laut Artikel 22 DSGVO unter Umständen das Recht,
                    nicht einer ausschließlich auf einer automatisierten
                    Verarbeitung (zum Beispiel Profiling) beruhenden
                    Entscheidung unterworfen zu werden.
                  </li>
                </ul>
                <br />
                <p>
                  Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das
                  Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen
                  Ansprüche in sonst einer Weise verletzt worden sind, können
                  Sie sich bei der Aufsichtsbehörde beschweren. Diese ist für
                  Österreich die Datenschutzbehörde, deren Website Sie unter
                  <a href="https://www.dsb.gv.at/">https://www.dsb.gv.at/</a>
                  finden und für Deutschland können Sie sich an die
                  <a href="https://www.bfdi.bund.de/"
                    >Bundesbeauftragte für den Datenschutz und die
                    Informationsfreiheit (BfDI)</a
                  >
                  wenden.
                </p>
              </v-col>
              <v-col cols="12">
                <h1>Webhosting</h1>
                <br />
                <h3>Webhosting Zusammenfassung</h3>
                <p>
                  Betroffene: Besucher der Website Zweck: professionelles
                  Hosting der Website und Absicherung des Betriebs Verarbeitete
                  Daten: IP-Adresse, Zeitpunkt des Websitebesuchs, verwendeter
                  Browser und weitere Daten. Mehr Details dazu finden Sie weiter
                  unten bzw. beim jeweils eingesetzten Webhosting Provider.
                  Speicherdauer: abhängig vom jeweiligen Provider, aber in der
                  Regel 2 Wochen Rechtsgrundlagen: Art. 6 Abs. 1 lit.f DSGVO
                  (Berechtigte Interessen)
                </p>
              </v-col>
              <v-col cols="12">
                <h2>Was ist Webhosting?</h2>
                <br />
                <p>
                  Wenn Sie heutzutage Websites besuchen, werden gewisse
                  Informationen – auch personenbezogene Daten – automatisch
                  erstellt und gespeichert, so auch auf dieser Website. Diese
                  Daten sollten möglichst sparsam und nur mit Begründung
                  verarbeitet werden. Mit Website meinen wir übrigens die
                  Gesamtheit aller Webseiten auf einer Domain, d.h. alles von
                  der Startseite (Homepage) bis hin zur aller letzten Unterseite
                  (wie dieser hier). Mit Domain meinen wir zum Beispiel
                  beispiel.de oder musterbeispiel.com.<br />
                  Wenn Sie eine Website auf einem Bildschirm ansehen möchten,
                  verwenden Sie dafür ein Programm, das sich Webbrowser nennt.
                  Sie kennen vermutlich einige Webbrowser beim Namen: Google
                  Chrome, Microsoft Edge, Mozilla Firefox und Apple Safari.<br />
                  Dieser Webbrowser muss sich zu einem anderen Computer
                  verbinden, wo der Code der Website gespeichert ist: dem
                  Webserver. Der Betrieb eines Webservers ist eine komplizierte
                  und aufwendige Aufgabe, weswegen dies in der Regel von
                  professionellen Anbietern, den Providern, übernommen wird.
                  Diese bieten Webhosting an und sorgen damit für eine
                  verlässliche und fehlerfreie Speicherung der Daten von
                  Websites.<br />
                  Bei der Verbindungsaufnahme des Browsers auf Ihrem Computer
                  (Desktop, Laptop, Smartphone) und während der Datenübertragung
                  zu und vom Webserver kann es zu einer Verarbeitung
                  personenbezogener Daten kommen. Einerseits speichert Ihr
                  Computer Daten, andererseits muss auch der Webserver Daten
                  eine Zeit lang speichern, um einen ordentlichen Betrieb zu
                  gewährleisten.
                </p>
              </v-col>
              <v-col cols="12">
                <h2>Warum verarbeiten wir personenbezogene Daten?</h2>
                <br />
                <p>Die Zwecke der Datenverarbeitung sind:</p>
                <ul>
                  <li>
                    Professionelles Hosting der Website und Absicherung des
                    Betriebs
                  </li>
                  <li>
                    Aus Gründen der Betriebssicherheit und zur Erstellung von
                    Zugriffsstatistiken
                  </li>
                </ul>
              </v-col>
              <v-col cols="12">
                <h2>Welche Daten werden verarbeitet?</h2>
                <br />
                <p>
                  Auch während Sie unsere Website jetzt gerade besuchen,
                  speichert unser Webserver, das ist der Computer auf dem diese
                  Webseite gespeichert ist, in der Regel automatisch Daten wie
                </p>
                <ul>
                  <li>
                    die komplette Internetadresse (URL) der aufgerufenen
                    Webseite (z. B.
                    https://www.apolloinstitute.at/beispielunterseite.html?tid=121751972)
                  </li>
                  <li>Browser und Browserversion (z. B. Chrome 87)</li>
                  <li>das verwendete Betriebssystem (z. B. Windows 10)</li>
                  <li>
                    die Adresse (URL) der zuvor besuchten Seite (Referrer URL)
                    (z. B.
                    https://www.beispielquellsite.at/vondabinichgekommen.html/)
                  </li>
                  <li>
                    den Hostnamen und die IP-Adresse des Geräts von welchem aus
                    zugegriffen wird (z. B. COMPUTERNAME und 194.23.43.121)
                  </li>
                  <li>Datum und Uhrzeit</li>
                  <li>in Dateien, den sogenannten Webserver-Logfiles</li>
                </ul>
              </v-col>
              <v-col cols="12">
                <h2>Wie lange werden Daten gespeichert?</h2>
                <br />
                <p>
                  In der Regel werden die oben genannten Daten zwei Wochen
                  gespeichert und danach automatisch gelöscht. Wir geben diese
                  Daten nicht weiter, können jedoch nicht ausschließen, dass
                  diese Daten beim Vorliegen von rechtswidrigem Verhalten von
                  Behörden eingesehen werden.<br />
                  Kurz gesagt: Ihr Besuch wird durch unseren Provider (Firma,
                  die unsere Website auf speziellen Computern (Servern) laufen
                  lässt), protokolliert, aber wir geben Ihre Daten nicht ohne
                  Zustimmung weiter!
                </p>
              </v-col>
              <v-col cols="12">
                <h2>Rechtsgrundlage</h2>
                <br />
                <p>
                  Die Rechtmäßigkeit der Verarbeitung personenbezogener Daten im
                  Rahmen des Webhosting ergibt sich aus Art. 6 Abs. 1 lit. f
                  DSGVO (Wahrung der berechtigten Interessen), denn die Nutzung
                  von professionellem Hosting bei einem Provider ist notwendig,
                  um das Unternehmen im Internet sicher und nutzerfreundlich
                  präsentieren zu können.<br />
                  Alle Texte sind urheberrechtlich geschützt.
                </p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-footer app>
      <v-row justify="center" no-gutters>
        <v-btn class="mr-2" text to="/dashboard">
          Login
        </v-btn>
        <v-btn class="ml-2" text to="/">
          Home
        </v-btn>
      </v-row>
    </v-footer>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      imgs: {
        head: require("@/assets/logo.svg"),
      },
    };
  },
  mounted() {
    this.$vuetify.theme.dark = localStorage.getItem("dark_mode");
  },
};
</script>

<style></style>
