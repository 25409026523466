<template>
  <dashboard v-cloak v-slot:default="slotProps">
    <span hidden>{{ (selected_user_id = slotProps.user) }}</span>
    <h2>Management Dashboard</h2>
    <v-row>
      <v-col cols="6">
        <v-card outlined>
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <v-toolbar class="toolbar" rounded="t-sm">
                  <v-toolbar-title class="white--text">
                    Current Month
                  </v-toolbar-title>
                </v-toolbar>
                <v-list align="right">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ sum_fiat_current_month[1].mgmt_fee }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        EUR
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider />
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ sum_fiat_current_month[0].mgmt_fee }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        USD
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col cols="6">
                <v-toolbar class="toolbar" rounded="t-sm">
                  <v-toolbar-title class="white--text">
                    Last Month
                  </v-toolbar-title>
                </v-toolbar>
                <v-list align="right">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ sum_fiat_last_month[1].mgmt_fee }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        EUR
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider />
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ sum_fiat_last_month[0].mgmt_fee }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        USD
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card outlined>
          <v-card-text>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Name
                    </th>
                    <th class="text-right">
                      Fee Amount
                    </th>
                    <th class="text-left">
                      Quote
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="i in managedaccounts" :key="i.name">
                    <td>{{ i.first_name }} {{ i.last_name }}</td>
                    <td>
                      <tr
                        v-for="j in i.all_profit_per_quote"
                        :key="j.quote_name"
                      >
                        <td>{{ j.mgmt_fee.toFixed(8) }}</td>
                      </tr>
                    </td>

                    <td>
                      <tr
                        v-for="j in i.all_profit_per_quote"
                        :key="j.quote_name"
                      >
                        <td>{{ j.quote_name }}</td>
                      </tr>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-card outlined>
      <v-toolbar>
        <v-toolbar-title>Profits</v-toolbar-title>

        <template v-slot:extension>
          <v-tabs v-model="tab" align-with-title>
            <v-tab>
              Current Month
            </v-tab>
            <v-tab>
              Last Month
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card>
            <v-card-text>
              <v-toolbar class="toolbar" rounded="t-sm">
                <v-toolbar-title class="white--text">
                  Current Month
                </v-toolbar-title>
              </v-toolbar>
              <v-list align="right">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ sum_fiat_current_month[1].mgmt_fee }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      EUR
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider />
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ sum_fiat_current_month[0].mgmt_fee }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      USD
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card>
            <v-card-text>
              <v-toolbar class="toolbar" rounded="t-sm">
                <v-toolbar-title class="white--text">
                  Last Month
                </v-toolbar-title>
              </v-toolbar>
              <v-list align="right">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ sum_fiat_last_month[1].mgmt_fee }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      EUR
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider />
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ sum_fiat_last_month[0].mgmt_fee }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      USD
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </dashboard>
</template>

<script>
import dashboard from "./Dashboard.vue";
import axios from "axios";
export default {
  name: "Management Dashboard",
  components: {
    dashboard,
  },
  data() {
    return {
      headers: [
        { text: "first_name", value: "first_name" },
        { text: "last_name", value: "last_name" },
        { text: "managed_since", value: "managed_since" },
        { text: "all_profit_per_quote", value: "all_profit_per_quote" },
      ],
      tab: "",
      managedaccounts: [],
      sum_current_month: [],
      sum_last_month: [],
      sum_fiat_current_month: [],
      sum_fiat_last_month: [],
    };
  },
  mounted() {
    this.$vuetify.theme.dark = localStorage.getItem("dark_mode");
    this.getData();
    // this.selected_user_id = localStorage.getItem("user_id");
    // if (this.selected_user_id != 0) {
    //   this.getData();
    // }
  },
  // watch: {
  //   selected_user_id: function() {
  //     this.getData();
  //   },
  // },
  methods: {
    async getData() {
      await axios
        .get(process.env.VUE_APP_API_URL + "/api/mgmt_dashboard")
        .then((response) => {
          console.log(response);
          this.managedaccounts = response.data.managed_accounts;
          this.sum_current_month = response.data.sum_current_month;
          this.sum_last_month = response.data.sum_last_month;
          this.sum_fiat_current_month = response.data.sum_fiat_current_month;
          this.sum_fiat_last_month = response.data.sum_fiat_last_month;
        });
    },
  },
};
</script>

<style></style>
